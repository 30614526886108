<template>
	<div>
		<el-row>
			<el-col>
				<el-form :inline='true'>
					<el-form-item>
						<toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
					</el-form-item>
				</el-form>
			</el-col>
		</el-row>
		<!-- 列表 -->
		<el-table
				:data="tableData"
				border
				style="width: 100%"
				v-loading="listLoading"
				ref="multipleTable"
				@current-change='currentChange'
				:highlight-current-row='true'
		>
			<el-table-column type='index' label="序号" width='80' align="center" :index='indexMethod'></el-table-column>
			<el-table-column prop="remark" label="职业等级方向"  align="center" ></el-table-column>
			<!--          <el-table-column prop="examModelTypeName" label="模式"  align="center" ></el-table-column>-->
			<el-table-column prop="pointNum" label="抽题总个数（个）"  align="center" ></el-table-column>
			<!--          <el-table-column prop="name" label="抽题总时间（分钟）"  align="center" >-->
			<!--            <template #default="scope">-->
			<!--              {{scope.row.limitDuration/60}}-->
			<!--            </template>-->
			<!--          </el-table-column>-->
			<el-table-column prop="score" label="抽题总分数"  align="center" ></el-table-column>
			<el-table-column prop="ddCommonStatus" label="状态"  align="center" >
				<template #default='scope'>
					<el-tag v-if='scope.row.ddCommonStatus === 1' type='success'>启用</el-tag>
					<el-tag v-else type='warning'>禁用</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="updateTime" label="最后操作时间"  align="center" >
				<!--            <template #default="scope">-->
				<!--              {{scope.row.createTime.split('T')[0]}}&nbsp;{{scope.row.createTime.split('T')[1].split('.')[0]}}-->
				<!--            </template>-->
			</el-table-column>
			<el-table-column prop="updateUserName" label="最后操作人员"  align="center" ></el-table-column>
		</el-table>
		<!--底部工具条-->
		<el-col :span="24" class="pageBar">
			<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="pages.pageIndex"
					:page-sizes="pages.pageArr"
					:page-size="pages.pageSize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="pages.dataCount">
			</el-pagination>
		</el-col>
		<el-dialog
				:title= "editFalse?'编辑':'添加规则'"
				:visible.sync="dialogs.addType.dialog"
				width="50%"
				:close-on-click-modal='false'
		>
			<el-form :model="addForm"  ref="addFormRef" label-width="160px">
				<el-form-item label="职业等级方向：">
					<el-select v-model="addForm.oproomProfessionDirId" @change="changeDieId" style="width: 80%" placeholder="请选择" filterable clearable>
						<el-option
								v-for="item in registerType"
								:key="item.id"
								:label="item.remark"
								:value="item.id"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<!--        <el-form-item label="模式：">-->
				<!--          <el-select v-model="addForm.ddExamModal" style="width: 80%" @change="changeModel" placeholder="请选择" filterable clearable>-->
				<!--            <el-option-->
				<!--                v-for="item in selectChange"-->
				<!--                :key="item.id"-->
				<!--                :label="item.examModelName"-->
				<!--                :value="item.id"-->
				<!--            >-->
				<!--            </el-option>-->
				<!--          </el-select>-->
				<!--        </el-form-item>-->
				<el-form-item label='状态：' prop='webUrl'>
					<template>
						<el-radio-group v-model="addForm.ddCommonStatus">
							<el-radio :label="1">启用</el-radio>
							<el-radio :label="2">禁用</el-radio>
						</el-radio-group>
					</template>
				</el-form-item>
				<el-form-item label="抽题配置（填写个数）">
					<el-table
							:data="addForm.careerList"
							border
							style="width: 100%"
							ref="multipleTable"
							@current-change='currentChanges'
							:highlight-current-row='true'
					>
						
						<el-table-column prop="name" label="职业功能"  align="center" ></el-table-column>
						<el-table-column prop="nums" label="关键技能鉴定点"  align="center" ></el-table-column>
						<el-table-column prop="" label="非关键技能鉴定点" style="padding: 0 " align="center" :rules="[{ required: true, message: 'Input is required', trigger: 'blur' }]">
							<!--              <template>-->
							<!--                <el-input style="border: none; " v-model=""></el-input>-->
							<!--              </template>-->
							<template #default="scope">
								<el-input :disabled="scope.row.name=== '合计（分值）'" style=" border-color: transparent;margin: 0;width: 100%;height: 100% "  v-model="scope.row.noKeySkillPointNum"
								          @blur="checkNoKeySkillPointNum(scope.row.noKeySkillPointNum,scope.row.numsNo)"></el-input>
							</template>
						</el-table-column>
					</el-table>
				</el-form-item>
			</el-form>
			<span v-if="!editFalse" slot="footer" class="dialog-footer">
                <el-button @click="dialogs.addType.dialog = false">取 消</el-button>
                <el-button type="primary" @click="addConfirm" :loading="loading">确认</el-button>
            </span>
			<span v-else slot="footer" class="dialog-footer">
                <el-button @click="dialogs.addType.dialog = false">取 消</el-button>
                <el-button type="primary" @click="showConfirm" :loading="loading">确认</el-button>
            </span>
		</el-dialog>
		<el-dialog
				title= "提示"
				:visible.sync="dialogs.addType.confirm"
				width="30%"
				:close-on-click-modal='false'
		>
			<el-form :model="addForm"  ref="addFormRef" label-width="100px">
				<el-form-item label="职业功能：">
					{{'该分类下的数据将停止使用”，确定禁用吗？'}}
				</el-form-item>
			</el-form>
			<span  slot="footer" class="dialog-footer">
                <el-button @click="dialogs.addType.confirm = false">取 消</el-button>
                <el-button type="primary" @click="editConfirm" :loading="loading">确认</el-button>
            </span>
		</el-dialog>
		<el-dialog
				title= "提示"
				:visible.sync="validaterType"
				width="30%"
				:close-on-click-modal='false'
		>
			<el-form  ref="addFormRef" label-width="100px">
				<el-form-item label="">
					{{'已有中级监控的组卷规则，是否要继续添加？'}}
				</el-form-item>
			</el-form>
			<span  slot="footer" class="dialog-footer">
                <el-button @click="validaterType = false">取 消</el-button>
                <el-button v-if="!editFalse" type="primary" @click="addToAdd" :loading="loading">确认</el-button>
                <el-button v-else type="primary" @click="editToAdd" :loading="loading">确认</el-button>
            </span>
		</el-dialog>
	</div>
</template>

<script>
import util from "../../../util/date";
import Toolbar from "../../components/Toolbar";
import { getButtonList } from "../../promissionRouter";
import {
	addExamPaperCompRules, checkIsExistUsingRules,
	getAreaDictionaryList, getExamIdentifyPointList, getExamModelTypeList,
	getExamPaperCompRulesList, getRulesDetailById,
	getOproomProfessionDirList,
	getOproomProfessionFuncList,
	updateExamPaperCompRules, updateExamIdentifyPoint,
} from '../../api/api'

export default {
	components:{Toolbar},
	data() {
		return {
			sumsIdentity:[],
			buttonList:[],
			tableData: [],
			areaArr: [],
			listLoading: false,
			//关于分页的obj
			pages: {
				pageSize: 20,//默认每页条数
				pageArr: [10, 20, 30, 40],//默认每页条数选择
				pageIndex: 1, //默认进入页
				dataCount: 20, //默认总页数
			},
			filters: {
				keyWord: '',
				areaStr: '',
				entyType: '',
			},
			dialogs:{
				addType:{
					dialog:false,
					confirm:false,
				},
				confirm:false,
			},
			addForm:{
				oproomProfessionDirId:undefined,//职业等级方向
				ddExamModal:3,//模式
				ddCommonStatus:1,
				careerList:[],
			},
			loading:false,
			addFormRules:[],
			professionType: [],
			editFalse:false,
			selectChange:[],
			registerType:[],
			dirID:undefined,
			addAllow:true,
			validaterType:false,
			careerList:[],
			
		}
	},
	methods: {
		checkNoKeySkillPointNum(val,value) {
			console.log(value,'value')
			if (val >value) {
				// callback(new Error('非关键技能点数不能超出限制'));
				this.$message({
					message:`非关键技能点数不能超出`+value,
					type:'warning'
				})
			} else {
			
			}
		},
		changeModel(val){
			let params = {
				examModelTypeId:val,
				oproomProfessionDirId:this.dirID,
			}
		},
		changeDieId(val){
			this.addForm.careerList = []
			this.dirID = val;
			console.log(val,'va')
			let params = {
				pageSize:200,
				pageIndex:1,
				professionDirType:val,
				ddCommonStatus:1,
			}
			getOproomProfessionFuncList(params).then(res=>{
				if (res.data.success){
					const nameArray = res.data.response.data.map(item=>({id:item.id,name:item.professionFuncName}))
					// const nameArray = nameArrays.filter(item=>(item.id===val))
					console.log(nameArray,'nameArray')
					let params = {
						pageIndex:1,
						pageSize:200,
						professionDirType:val
					}
					let paramse = {
						id:this.needData?this.needData.id:undefined,
					}
					console.log(this.editFalse,'eeee')
					let resname = this.editFalse?getRulesDetailById(paramse): getExamIdentifyPointList(params)
					resname.then(res=>{
						if (res.data.success){
							const identifyArray = this.editFalse?res.data.response:res.data.response.data
							console.log(identifyArray,'identifyArray')
							console.log(nameArray,'nameArray')
							nameArray.forEach(item=>{
								const arrAll = identifyArray.filter(ites=>ites.oproomProfessionFuncId === item.id)
								console.log(arrAll,'arrall')
								const resArry = arrAll.filter(ites=>ites.skillType === 2)
								const arr = arrAll.filter(ites=>ites.skillType === 1)
								console.log(resArry,'arry')
								const scores = arr.map(items=>items.sumScore)
								const scoresNo = resArry.map(items=>items.sumScore)
								const sum = scores.reduce((accumulator, currentValue) => {
									return accumulator + currentValue;
								}, 0);
								const sumNo = scoresNo.reduce((accumulator, currentValue) => {
									return accumulator + currentValue;
								}, 0);
								const ids = arr.map(items=>items.id)
								item.keySkillPointList = ids
								item.identifySumScore = sum
								item.nums = arr?arr.length:0
								item.numsNo =arrAll&&arr? (arrAll.length - arr.length):0
								item.noKeySkillPointNum = resArry?resArry.length:undefined
								item.numsNos = sumNo
							})
							let sums = nameArray.map(ite=>ite.identifySumScore)
							const sumsother = sums.reduce((accumulator, currentValue) => {
								return accumulator + currentValue;
							}, 0);
							let sumsNo = nameArray.map(ite=>ite.numsNo)
							const sumsothers = sumsNo.reduce((accumulator, currentValue) => {
								return accumulator + currentValue;
							}, 0);
							const identifyNo = identifyArray.find(item=>item.skillType === 2)
							nameArray.push({
								id:0,
								keySkillPointList:undefined,
								identifySumScore:undefined,
								nums:sumsother,
								noKeySkillPointNum:+sumsothers*(identifyNo?(+identifyNo.sumScore):1),
								name:'合计（分值）'
							})
							this.addForm.careerList = nameArray
							// this.addForm.ddExamModal = undefined
							this.addForm.ddCommonStatus = 2;
							this.addForm.ddCommonStatus = 1;
							if (this.editFalse){
								this.addForm.ddExamModal = this.needData.examModelTypeId
							}
							console.log(nameArray,'name')
						}
						
					})
				}
			})
			
		},
		handleAdd(){
			this.addAllow = true;
			this.addForm={
				oproomProfessionDirId:undefined,//职业等级方向
				examModelTypeId:undefined,//模式
				ddCommonStatus:1,
				// careerList:[],
			}
			this.changeSelect()
			this.editFalse = false
			this.dialogs.addType.dialog = true;
		},
		//选项
		changeSelect(){
			let param = {
				pageIndex:1,
				pageSize:20,
				ddCommonStatus:1
			}
			getOproomProfessionDirList(param).then(res=>{
				if (res.data.success){
					this.registerType = res.data.response.data
				}
			})
			let re = {
				pageIndex:1,
				pageSize:20,
			}
			getExamModelTypeList(re).then(res=>{
				if (res.data.success){
					this.selectChange = res.data.response.data
				}
			})
		},
		rowClass () {
			return 'padding:8px!important;'
		},
		callFunction(item) {
			this[item.func].apply(this, item);
		},
		getDataList(){
			this.pages = {
				pageIndex: 1,
				pageSize: 20,
			}
			this.getListData()
		},
		// 列表查询接口
		getListData(){
			var params = {
				pageIndex: this.pages.pageIndex,
				pageSize: this.pages.pageSize,
				examModal:undefined,//考试模式
				examModelTypeId:3,
				oproomProfessionDirId:undefined,//职业等级方向id
			}
			this.listLoading = true
			getExamPaperCompRulesList(params).then(res => {
				var result = res.data
				if(result.success) {
					this.tableData = result.response.data
					this.pages.dataCount = result.response.dataCount
				}
				this.listLoading = false
			})
		},
		async addConfirm(){
			console.log(this.addForm.careerList,'233232323323232');
			this.addForm.careerList[this.addForm.careerList.length-1].name === '合计（分值）'?this.addForm.careerList.pop():undefined;
			this.addForm.careerList.forEach(item=>{
				item.noKeySkillPointNum = parseInt(item.noKeySkillPointNum)
				if (item.noKeySkillPointNum >item.numsNo) {
					// callback(new Error('非关键技能点数不能超出限制'));
					this.$message({
						message:item.name+`的非关键技能点数不能超出`+item.numsNo,
						type:'warning'
					})
					this.addAllow = false
				}
			})
			let params = {
				examModelTypeId:this.addForm.ddExamModal,//
				oproomProfessionDirId:this.addForm.oproomProfessionDirId,//
			}
			checkIsExistUsingRules(params).then(res=>{
				if (res.data.success){
					const arr = res.data.response
					console.log(arr)
					if (arr.isExistRules === true&&arr.isUsableRules===true){
						this.$message({
							message:'已有同一条中级监控组卷规则',
							type:'warning'
						})
					}else if (arr.isExistRules === true&&arr.isUsableRules===false){
						this.validaterType = true
					}else if(arr.isExistRules===false){
						this.addToAdd();
					}
				}
			})
			
		},
		async addToAdd(){
			if (this.addAllow){
				let params = {
					ddCommonStatus:this.addForm.ddCommonStatus,//状态值 1，启用；2，禁用
					id:undefined,
					examModelTypeId:this.addForm.ddExamModal,//考试模式
					oproomProfessionDirId:this.addForm.oproomProfessionDirId,//职业等级方向ID
					savePointAndProFuncReqList:this.addForm.careerList,
					// saveNoKeyAndProFuncResList:[],//非关键技能鉴定点数量
				}
				const res = await addExamPaperCompRules(params)
				if (res.data.success){
					this.getDataList()
					this.dialogs.addType.dialog = false ;
					this.validaterType = false;
					this.$message({
						message:res.data.message,
						type:'success'
					})
				}else{
					this.$message({
						message:res.data.message,
						type:'warning'
					})
				}
			}
		},
		//编辑
		handleEdit(){
			if (!this.needData){
				this.$message({
					message:'请选择一条数据',
					type:'warning'
				})
				return;
			}
			this.editFalse = true;
			this.changeDieId(this.needData.oproomProfessionDirId)
			this.addForm = {
				ddCommonStatus:this.needData.ddCommonStatus,//状态值 1，启用；2，禁用
				id:this.needData.id,
				ddExamModal:this.needData.examModelTypeId,//考试模式
				oproomProfessionDirId:this.needData.oproomProfessionDirId,//职业等级方向ID
				savePointAndProFuncReqList:this.addForm.careerList,
			}
			this.changeSelect()
			this.dialogs.addType.dialog = true;
		},
		showConfirm() {
			if (this.addForm.ddCommonStatus === 2){
				this.dialogs.addType.confirm = true
			}else {
				this.editConfirm()
			}
		},
		async editConfirm(){
			this.addForm.careerList[this.addForm.careerList.length-1].name === '合计（分值）'?this.addForm.careerList.pop():undefined;
			this.addForm.careerList.forEach(item=>{
				item.noKeySkillPointNum = parseInt(item.noKeySkillPointNum)
				if (item.noKeySkillPointNum >item.numsNo) {
					// callback(new Error('非关键技能点数不能超出限制'));
					this.$message({
						message:item.name+`的非关键技能点数不能超出`+item.numsNo,
						type:'warning'
					})
					this.addAllow = false
				}
			})
			// let params = {
			//   ddExamModal:this.addForm.ddExamModal,//
			//   oproomProfessionDirId:this.addForm.oproomProfessionDirId,//
			// }
			// checkIsExistUsingRules(params).then(res=>{
			//   if (res.data.success){
			//     const arr = res.data.response
			//     console.log(arr)
			//     if (arr.isExistRules === true&&arr.isUsableRules===true){
			//       this.$message({
			//         message:'已有同一条中级监控组卷规则',
			//         type:'warning'
			//       })
			//     }else if (arr.isExistRules === true&&arr.isUsableRules===false){
			//       this.validaterType = true
			//     }else if(arr.isExistRules===false){
			await   this.editToAdd();
			//     }
			//   }
			// })
			
		},
		async editToAdd(){
			if (this.addAllow){
				console.log(this.needData,'11111')
				let params = {
					ddCommonStatus:this.addForm.ddCommonStatus,//状态值 1，启用；2，禁用
					id:this.needData.id,
					examModelTypeId:this.addForm.ddExamModal,//考试模式
					oproomProfessionDirId:this.addForm.oproomProfessionDirId,//职业等级方向ID
					savePointAndProFuncReqList:this.addForm.careerList,
				}
				const res = await updateExamPaperCompRules(params)
				if (res.data.success){
					this.getDataList()
					this.dialogs.addType.dialog = false ;
					this.dialogs.addType.confirm = false ;
					this.validaterType = false;
					this.$message({
						message:res.data.message,
						type:'success'
					})
				}else{
					this.dialogs.addType.dialog = false ;
					this.dialogs.addType.confirm = false ;
					this.validaterType = false;
					this.$message({
						message:res.data.message,
						type:'warning'
					})
				}
				this.editFalse = false;
			}
		},
		//禁用
		async disable(){
			if (!this.needData){
				this.$message({
					message:'请选择一条数据',
					type:'warning'
				})
				return;
			}
			this.editFalse = true;
			this.changeDieId(this.needData.oproomProfessionDirId)
			this.changeSelect()
			setTimeout(()=>{
				console.log(this.addForm.careerList,'3333333');
				(this.addForm.careerList[this.addForm.careerList.length-1].id === 0)?this.addForm.careerList.pop():undefined;
				// this.addForm.careerList.forEach(item=>{
				//   item.noKeySkillPointNum = parseInt(item.noKeySkillPointNum)
				// })
				let params = {
					ddCommonStatus:2,//状态值 1，启用；2，禁用
					id:this.needData.id,
					examModelTypeId:this.needData.examModelTypeId,//考试模式
					oproomProfessionDirId:this.needData.oproomProfessionDirId,//职业等级方向ID
					savePointAndProFuncReqList:this.addForm.careerList,
				}
				updateExamPaperCompRules(params).then(res=>{
					if (res.data.success){
						this.getDataList()
						this.dialogs.addType.dialog = false ;
						this.dialogs.addType.confirm = false ;
						this.validaterType = false;
						this.$message({
							message:res.data.message,
							type:'success'
						})
					}else{
						this.dialogs.addType.dialog = false ;
						this.dialogs.addType.confirm = false ;
						this.validaterType = false;
						this.$message({
							message:res.data.message,
							type:'warning'
						})
					}
					this.editFalse = false;
				})
				
			},2000)
			
			
			
		},
		
		
		
		
		// 获取省
		getAreaList() {
			var params = {
				parentId: 1,
			}
			getAreaDictionaryList(params).then(res => {
				if (res.data.success) {
					this.areaArr = res.data.response.reverse();
				}
			})
		},
		//获取字符空间
		async getNamespace(){
			let params = {
				pageIndex:undefined,
				pageSize:undefined,
			}
			const res = await  getByNamespace(params)
			console.log(res,'res')
		},
		
		
		// 点击table某一行
		currentChange(val) {
			this.needData = val
			console.log(val,'val')
		},
		// 点击table某一行
		currentChanges(val) {
			// this.needData = val
			console.log(val,'val')
		},
		// 分页
		handleCurrentChange(val) {
			this.pages.pageIndex = val;
			this.getListData();
		},
		handleSizeChange(val){
			this.pages.pageSize = val;
			this.getListData();
		},
		// 时间格式化
		formatCreateTime: function(row, column) {
			return !row || row == ""
					? ""
					: util.formatDate.format(new Date(row), "yyyy-MM-dd hh:mm");
		},
		// 表格数据分页条数累计
		indexMethod(index) {
			var currentPage = this.pages.pageIndex
			var pageSize = this.pages.pageSize
			return (index + 1) + (currentPage - 1) * pageSize
		},
	},
	watch: {
	
	},
	created(){
		this.utils = util
		let routers = window.localStorage.router
				? JSON.parse(window.localStorage.router)
				: [];
		this.buttonList = getButtonList(this.$route.path, routers);
		this.getListData();
		// this.getAreaList()
	},
}
</script>

<style lang="stylus" scoped>
.projectName {
	text-align: center;
}
.projectSelect {
	margin-bottom: 15px;
}
.projectTitle {
	margin-bottom: 15px
}
.replyBtn {
	margin: 10px 0;
	color: #409eff;
	cursor: pointer;
	width: 30px;
}
.replyMessage {
	background: #eee;
	line-height: 30px;
	padding: 5px;
	border-radius: 2px;
	margin: 5px 15px;
}
.titleTimer {
	margin-left: 10px;
}
.green_t {
	color: green;
}
.red_t {
	color: red;
}
</style>
